body {
  background-color: #e4e5e6 !important;
}

.app-footer {
  padding: 14px 1rem !important;
}

.white-space-nowrap {
  white-space: nowrap;
}

.form-check-inline .form-check-input {
  position: relative !important;
  top: 2px;
}

.react-bootstrap-daterangepicker-container {
  display: block !important;
}

.main .container-fluid {
  padding-top: 1px !important;
}

.table-input-checkbox {
  position: relative !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* required in label style */
.required-red,
.required-green {
  display: inline-block;
  margin-left: 5px;
  top: -2px;
  position: relative;
}

.required-red {
  /* color: #dc3545; */
  color: #d39e00;
  font-weight: bold;
}
.required-green {
  color: #28a745
}

/* basic table */
.basic-table {
  overflow-y: auto;
}
.basic-table table {
  background-color: #fff;
}
.basic-table th.sortable-th {
  cursor: pointer;
}
.basic-table th.sortable-th span.table-th {
  display: inline-flex;
  align-items: center;
}
.basic-table th.sortable-th span.table-th span.icon {
  margin-left: 5px;
  display: flex;
}

/* loading wrapper */
.content-wrapper {
  position: relative;
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* footer */
.app-footer {
  width: auto;
  position: relative !important;
  z-index: 1;
}

/* manual form */
.forwarding-shipping-agent .form-group {
  margin-bottom: 5px;
}